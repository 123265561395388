import Menus from "Components/Shared/menu";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MoreVert from "@mui/icons-material/MoreVert";
import { useMenu } from "Utils/hooks";

import { Link } from "react-router-dom";
import { TransactionMenuFunc } from "./type";
import { useEffect, useState } from "react";
import { UsersDetails } from "Utils/interface";

const TransactionMenu: TransactionMenuFunc = (props) => {
  const [userDetails, setUserDetails] = useState<UsersDetails | null>(null);
  const { anchorEl, menuIsOpen, closeMenu, openMenu } = useMenu();
  useEffect(() => {
    const userDetailsString = localStorage.getItem("user");
    if (userDetailsString) {
      const userDetailsObject = JSON.parse(userDetailsString);
      setUserDetails(userDetailsObject);
    }
  }, []);
  return (
    <>
      <IconButton onClick={openMenu}>
        <MoreVert />
      </IconButton>
      <Menus anchorEl={anchorEl} open={menuIsOpen} closeMenu={closeMenu}>
        <div>
          {userDetails?.role == "COMPLIANCE" ? (
            <Link to={`/transaction-details?id=${props.transactionId}`}>
              <MenuItem disableRipple>View Transaction</MenuItem>
            </Link>
          ) : (
            <>
              <Link to={`/wallet?globalId=${props.walletId}`}>
                <MenuItem disableRipple>View Wallet</MenuItem>
              </Link>
              <Link to={`/transaction-details?id=${props.transactionId}`}>
                <MenuItem disableRipple>View Transaction</MenuItem>
              </Link>
            </>
          )}
        </div>
      </Menus>
    </>
  );
};

export default TransactionMenu;
