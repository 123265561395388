import Menus from "Components/Shared/menu";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MoreVert from "@mui/icons-material/MoreVert";
import { useMenu, useToast } from "Utils/hooks";
import { Link, useNavigate } from "react-router-dom";
import { MenuFunc } from "./type";
import ChangeUserStatus from "./changeUserStatus";
import ChangeWalletGeneralStatus from "./changeWalletGeneralStatus";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Utils";
import Snackbar from "@mui/material/Snackbar";
import { Alert, AlertColor } from "@mui/material";
import ActionButton from "Components/Shared/button";
import { UsersDetails } from "Utils/interface";
import { useSearchParams } from "react-router-dom";
import CreateBDC from "./createBdc";

const deleteUser = async (userId: string) => {
    await request.delete(`/user/${userId}`);
};

const Usermenu: MenuFunc = (props) => {
    const { anchorEl, menuIsOpen, closeMenu, openMenu } = useMenu();
    const { toggleToast, toastMessage, closeToast, toastOptions } = useToast();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [userDetails, setUserDetails] = useState<UsersDetails | null>(null);
    const [getQuery] = useSearchParams();
    const id = getQuery.get("userId");

    const getUserData = async () => {
        const { data } = await request.get({ url: `/user/${props.userId}` });
        return data;
    };

    const { data, refetch } = useQuery({
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        queryKey: [props.userId],
        queryFn: getUserData,
        retry: 2,
        // staleTime: 86400000,
    });

    const handleRefetch = () => {
        // Trigger the refetch of users data
        refetch();
    };

    useEffect(() => {
        const userDetailsString = localStorage.getItem("user");
        if (userDetailsString) {
            const userDetailsObject = JSON.parse(userDetailsString);
            setUserDetails(userDetailsObject);
        }
    }, []);

    function redirectLocation() {
        localStorage.setItem("userId", props.userId);
    }

    const mutation = useMutation(() => deleteUser(props.userId), {
        onSuccess: () => {
            toggleToast("User deleted successfully");
            // You can navigate to a different page or take any other action after deletion
            queryClient.invalidateQueries(["user", props.userId]);
            props.refetch();
            navigate(".", { replace: true });
        },
        onError: (error: any) => {
            toggleToast(`Error deleting user: ${error.message}`);
        },
        onSettled: () => {
            // Invalidate and refetch the user data query to reflect the changes
            queryClient.invalidateQueries(["user", props.userId]);
        },
    });

    const handleDelete = () => {
        toggleToast("Are you sure you want to delete this user?", {
            variant: "warning",
            actionButtons: [
                {
                    label: "Confirm",
                    onClick: () => {
                        mutation.mutate();
                        closeToast(); // Close the toast after confirming deletion
                    },
                },
                {
                    label: "Cancel",
                    onClick: () => closeToast(), // Close the toast without deleting
                },
            ],
        });
    };

    return (
        <>
            <IconButton onClick={openMenu}>
                <MoreVert />
            </IconButton>
            <Menus anchorEl={anchorEl} open={menuIsOpen} closeMenu={closeMenu}>
                {userDetails?.role === "MARKETING" ? (
                    <div>
                        <Link
                            onClick={redirectLocation}
                            to={`/wallet?globalId=${props.globalId}`}
                        >
                            <MenuItem disableRipple>View Wallets</MenuItem>
                        </Link>
                        <Link
                            onClick={redirectLocation}
                            to={`/user?userId=${props.userId}`}
                        >
                            <MenuItem disableRipple>View User Data</MenuItem>
                        </Link>
                        {data?.unsubscribe ? (
                            <Link
                                onClick={() =>
                                    alert("This user is unsubscribed to emails")
                                }
                                to={``}
                            >
                                <MenuItem disableRipple>Send Mail</MenuItem>
                            </Link>
                        ) : (
                            <Link
                                onClick={redirectLocation}
                                to={`/send-mail?email=${props.userEmail}&firstname=${props.firstname}&lastname=${props.lastname}&userId=${props.userId}`}
                            >
                                <MenuItem disableRipple>Send Mail</MenuItem>
                            </Link>
                        )}
                    </div>
                ) : (
                    <div>
                        {data?.unsubscribe ? (
                            <Link
                                onClick={() =>
                                    alert("This user is unsubscribed to emails")
                                }
                                to={``}
                            >
                                <MenuItem disableRipple>Send Mail</MenuItem>
                            </Link>
                        ) : (
                            <Link
                                onClick={redirectLocation}
                                to={`/send-mail?email=${props.userEmail}&firstname=${props.firstname}&lastname=${props.lastname}&userId=${props.userId}`}
                            >
                                <MenuItem disableRipple>Send Mail</MenuItem>
                            </Link>
                        )}

                        <Link
                            onClick={redirectLocation}
                            to={`/push-notification?userId=${props.userId}&firstname=${props.firstname}`}
                        >
                            <MenuItem disableRipple>
                                Send Push Notification
                            </MenuItem>
                        </Link>
                        <Link
                            onClick={redirectLocation}
                            to={`/offers?userGlobalId=${props.globalId}`}
                        >
                            <MenuItem disableRipple>Offers</MenuItem>
                        </Link>
                        <Link
                            onClick={redirectLocation}
                            to={`/orders?userGlobalId=${props.globalId}`}
                        >
                            <MenuItem disableRipple>Orders</MenuItem>
                        </Link>
                        <Link
                            onClick={redirectLocation}
                            to={`/transactions?walletId=${props.walletId}`}
                        >
                            <MenuItem disableRipple>Transactions</MenuItem>
                        </Link>
                        <Link
                            onClick={redirectLocation}
                            to={`/wallet?globalId=${props.globalId}`}
                        >
                            <MenuItem disableRipple>View Wallets</MenuItem>
                        </Link>
                        <ChangeWalletGeneralStatus
                            defaultStatus={props.defaultStatus}
                            toggleToast={props.toggleToast}
                            refetch={handleRefetch}
                            walletId={props.walletId}
                            walletStatus={data?.walletStatus}
                        />
                        <Link
                            onClick={redirectLocation}
                            to={`/kyc/user/${props.userId}`}
                        >
                            <MenuItem disableRipple>View KYC</MenuItem>
                        </Link>
                        <Link
                            onClick={redirectLocation}
                            to={`/user?userId=${props.userId}`}
                        >
                            <MenuItem disableRipple>View User Data</MenuItem>
                        </Link>
                        <ChangeUserStatus
                            defaultStatus={props.defaultStatus}
                            toggleToast={props.toggleToast}
                            refetch={props.refetch}
                            userId={props.userId}
                            userType={props.userType}
                        />
                        <CreateBDC
                            toggleToast={props.toggleToast}
                            refetch={props.refetch}
                            userId={props.userId}
                            userGlobalId={props.globalId}
                        />
                        {data?.unsubscribe ? (
                            <Link
                                onClick={() =>
                                    alert("This user is already unsubscribed")
                                }
                                to={``}
                            >
                                <MenuItem disableRipple>
                                    Unsubscsribe User
                                </MenuItem>
                            </Link>
                        ) : (
                            <Link
                                onClick={redirectLocation}
                                to={`/user/${props.userId}/unsubscribe`}
                            >
                                <MenuItem disableRipple>
                                    Unsubscsribe User
                                </MenuItem>
                            </Link>
                        )}
                        <MenuItem
                            onClick={handleDelete}
                            sx={{ color: "red" }}
                            disableRipple
                        >
                            Delete User
                        </MenuItem>
                    </div>
                )}
            </Menus>
            <Snackbar
                open={Boolean(toastMessage)}
                autoHideDuration={6000} // Adjust as needed
                onClose={() => closeToast()}
                anchorOrigin={{ vertical: "top", horizontal: "right" }} // Change anchor origin to top right
            >
                <Alert
                    onClose={() => {
                        closeToast();
                        navigate("/users");
                        window.location.reload();
                    }}
                    severity={toastOptions.variant as AlertColor} // Explicitly cast to AlertColor
                    sx={{
                        width: "400px", // Adjust the width as needed
                        fontSize: "18px", // Adjust the font size as needed
                        borderRadius: "8px", // Add border-radius for a finer look
                        padding: "16px", // Add padding for a bigger look
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", // Add box-shadow for a subtle elevation
                        backgroundColor: "white",
                        color: "black",
                    }}
                >
                    {toastMessage}
                    {/* Render action buttons based on toastOptions */}
                    {toastOptions.actionButtons && (
                        <div>
                            {toastOptions.actionButtons.map((button) => (
                                <ActionButton
                                    onClickCapture={() => {
                                        navigate("/users");
                                        window.location.reload(); // This will force a page refresh
                                    }}
                                    variant="contained"
                                    sx={{
                                        marginLeft: "5px",
                                        marginTop: "5px",
                                        paddingY: "5px",
                                        paddingX: "10px",
                                    }}
                                    key={button.label}
                                    onClick={button.onClick}
                                >
                                    {button.label}
                                </ActionButton>
                            ))}
                        </div>
                    )}
                </Alert>
            </Snackbar>
        </>
    );
};
export default Usermenu;
