import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
import Wrapper from "Components/Wrapper";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { request, toLocaleDate } from "Utils";
import { useDailog } from "Utils/hooks";
import { CurrencyPairInt, CurrencyPairsFunc, CurrencyPairMenu } from "./type";
import NoContentFound from "Components/Shared/noContentFound";
import { Container } from "@mui/material";
import CurrencyPairDialog from "./CurrencyPairDialog";
import FabButton from "Components/Shared/fab";
import { MenuItem } from "@mui/material";
import UpdateIcon from '@mui/icons-material/Update';
import Toast from "Components/Shared/toast";
import { useToast } from "Utils/hooks";

const apiEndpoints = {
    currencyPairConfig: "/currency-pair-config",
    countries: "/utility/countries"
};
const getCurrencyPairConfig = async () => {
    const { data: currencyPairConfig = [] } = await request.get({
        url: apiEndpoints.currencyPairConfig,
    });
    return currencyPairConfig;
};

const getCountries = async () => {
    const { data: countries = [] } = await request.get({
        url: apiEndpoints.countries,
    });
    return countries;
};


const CurrencyPairs: CurrencyPairsFunc = () => {
    const exchangeDialog = useDailog();
    const { toggleToast, toastMessage } = useToast();
    const [pageId, setPageId] = useState(1);
    const [limit, setLimit] = useState(10);
    const [paginatedData, setPaginatedData] = useState([]);
    const [currentRowData, setCurrentRowData] = useState<CurrencyPairInt | undefined>({
        toCurrencyIso: "",
        fromCurrencyIso: "",
        config: {
            type: "",
            value: 0,
        },
        createdAt: "",
        updatedAt: "",
    });

    const [isUpdate, setIsUpdate] = useState<boolean | undefined>(false);
    const { data: currencyPair = [], error: configError, isLoading: isConfigLoading, refetch: refetchConfig } = useQuery({
        queryKey: ["Currency-Pair-Config", pageId, limit],
        queryFn: getCurrencyPairConfig,
        retry: 2,
    });

    const { data: countries = [], error: countryError, isLoading: isCountryLoading, refetch: refetchCountries } = useQuery({
        queryKey: ["Country-List", pageId, limit],
        queryFn: getCountries,
        retry: 2,
    });

    useEffect(() => {
        if (currencyPair) {
            const startIndex = (pageId - 1) * limit;
            const endIndex = startIndex + limit;
            // TODO:Change to backend pagination
            setPaginatedData(currencyPair.slice(startIndex, endIndex));
        }
    }, [currencyPair, pageId, limit]);

    const columns = [
        { minWidth: 20, name: "No", key: "index" },
        { minWidth: 20, name: "Pair Id", key: "_id" },
        { minWidth: 20, name: "From Currency", key: "fromCurrencyIso2" },
        { minWidth: 50, name: "To Currency", key: "toCurrencyIso2" },
        { minWidth: 50, name: "Fee Type", key: "feeType" },
        { minWidth: 30, name: "Fee", key: "feeValue" },
        { minWidth: 50, name: "Created At", key: "createdAt" },
        { minWidth: 50, name: "Action", key: "action" },
    ];

    const handleClick: CurrencyPairMenu['handleClick'] = (currencyPairData, forUpdate) => {
        exchangeDialog.openDialog();
        setIsUpdate(forUpdate);
        setCurrentRowData(currencyPairData);
    };
    const currencyPairsLists = paginatedData?.map((list: CurrencyPairInt, index: number) => ({
        index: ++index + limit * (pageId - 1),
        ...list,
        feeType: list.config?.type,
        feeValue: list.config?.value,
        createdAt: toLocaleDate(list?.createdAt),
        action: <MenuItem onClick={() => handleClick(list, true)}>
            <UpdateIcon /> Update Exchange Rate
        </MenuItem>
    }));
    if (isConfigLoading || isCountryLoading) return <Loading />;
    if (configError || countryError) return <ErrorPage error={configError || countryError} />;

    return (
        <Wrapper>
            <Container maxWidth="lg" sx={{ height: '65.5vh' }}>
                <div>
                    {currencyPairsLists?.length ? (
                        <StickyHeadTable
                            refetch={refetchConfig || refetchCountries}
                            data={currencyPairsLists}
                            dataLength={currencyPair?.length}
                            columns={columns}
                            setLimit={setLimit}
                            limit={limit}
                            setPageId={setPageId}
                            pageId={pageId}
                            onClick={(row:any) => handleClick(row, true)}
                        />
                    ) : (
                        <NoContentFound />
                    )}
                </div>
                <FabButton onClick={handleClick} />
                <CurrencyPairDialog
                    refetch={refetchConfig || refetchCountries}
                    isOpen={exchangeDialog.isOpen}
                    closeDialog={exchangeDialog.closeDialog}
                    currencyPairData={isUpdate ? currentRowData : null}
                    apiEndpoint={apiEndpoints.currencyPairConfig}
                    countryList={countries}
                    title={isUpdate ? "Update Exchange Fee" : "Add Exchange Fee"}
                    isUpdate={isUpdate}
                    toggleToast={toggleToast}
                />
                {Boolean(toastMessage) && (
                    <Toast
                        open={Boolean(toastMessage)}
                        message={toastMessage}
                        onClose={() => toggleToast("")}
                    />
                )}

            </Container>

        </Wrapper>
    );
};


export default CurrencyPairs;