import Wrapper from "Components/Wrapper";
import { useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { EmailPageFunc, Mail } from "./type";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Stack from "@mui/material/Stack";
import ChipInput from "./chipInput";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Image from "@mui/icons-material/Image";
import Send from "@mui/icons-material/Send";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import Delete from "@mui/icons-material/Delete";
import { request } from "Utils";
import Box from "@mui/material/Box";

const EmailPage: EmailPageFunc = () => {
    const [getQuery] = useSearchParams();
    const email = getQuery.get("email");
    const firstname = getQuery.get("firstname");
    const lastname = getQuery.get("lastname");
    const userId = getQuery.get("userId");
    const [sentStatus, setStatus] = useState({
        type: "empty",
        message: "",
    });
    const [mailList, setMailList] = useState<Mail[]>([]);
    const location = useLocation();
    const { data } = location.state || {};
    const [mail, setMail] = useState({
        subject: "",
        to: "",
        from: "",
        mail_body: " ",
    });
    const [attachments, setAttachments] = useState<File[]>([]);

    useEffect(() => {
        const tempMailList = new Set(mailList.map((user) => user.email));
        const newMailList = [...mailList];

        if (data !== undefined) {
            const itemD = JSON.parse(data);
            itemD.forEach((item: any) => {
                const user = {
                    email: item.email,
                    fullName: `${item?.firstname} ${item?.lastname}`,
                    userId: `${userId}`,
                };
                if (!tempMailList.has(user.email)) {
                    tempMailList.add(user.email);
                    newMailList.push(user);
                }
            });
        } else if (email != null && firstname != null && lastname != null) {
            const user = {
                email: email,
                fullName: `${firstname} ${lastname}`,
                userId: `${userId}`,
            };
            if (!tempMailList.has(user.email)) {
                tempMailList.add(user.email);
                newMailList.push(user);
            }
        }

        setMailList(newMailList);
    }, [email, firstname, lastname, userId, data]);

    const removeFile = (item: any) => {
        const filtered = attachments.filter(
            (attachment) => attachment !== item
        );
        setAttachments(filtered);
    };

    const sendMail = async () => {
        const uniqueMailList = Array.from(
            new Map(mailList.map((user) => [user.email, user])).values()
        );
        const mail_body = {
            subject: mail.subject,
            message: mail.mail_body,
            sender: mail.from,
            recipients: uniqueMailList.map((user) => ({
                to: user.email,
                dynamic_template_data: {
                    fullName: user.fullName,
                    id: user.userId,
                },
            })),
        };

        try {
            await request.post({
                url: "/utility/send-bulk-email",
                data: mail_body,
            });
            setStatus({
                type: "loading",
                message: "sending mails",
            });
            setMail({
                subject: "",
                to: "",
                from: "",
                mail_body: " ",
            });
            setTimeout(() => {
                setStatus({
                    type: "success",
                    message: "Emails Sent Successfully",
                });
            }, 2000);
        } catch (error: any) {
            console.error("Error sending emails:", error);
            setStatus({ type: "error", message: error.message });
        }
    };
    const enforceResponsiveImages = (editorData: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(editorData, "text/html");

        const imgElement = doc.querySelector("img");
        if (imgElement) {
            imgElement.setAttribute("width", "100%"); // New width
            imgElement.setAttribute("height", "auto"); // New height
        }

        const updatedHtmlString = doc.body.innerHTML;
        return updatedHtmlString;
    };
    const senders = [
        "info@monirates.com",
        "hello@monirates.com",
        "product-updates@monirates.com",
    ];

    return (
        <Wrapper>
            <Box sx={{ height: "85.5vh", position: "relative" }}>
                <Stack spacing={1} py={1}>
                    {sentStatus.type !== "empty" && (
                        <p
                            style={
                                sentStatus.type !== "error"
                                    ? {
                                          color: "green",
                                          fontSize: 20,
                                          marginBottom: 10,
                                          fontWeight: "bold",
                                      }
                                    : {
                                          color: "red",
                                          fontSize: 20,
                                          marginBottom: 10,
                                          fontWeight: "bold",
                                      }
                            }
                        >
                            {sentStatus.type === "loading" ? (
                                <CircularProgress />
                            ) : (
                                sentStatus.message
                            )}
                        </p>
                    )}
                    <div>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>From</InputLabel>
                            <Select
                                name="fromId"
                                labelId="fromId"
                                label="From"
                                value={mail.from}
                                onChange={(e: any) =>
                                    setMail({ ...mail, from: e.target.value })
                                }
                                required
                            >
                                {senders?.map((name: string, index: number) => (
                                    <MenuItem
                                        key={`${index}-type`}
                                        value={name}
                                    >
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <ChipInput label="To" id="to" users_list={mailList} />
                        <TextField
                            sx={{ width: "100%", marginBottom: "10px" }}
                            label="Subject"
                            value={mail.subject}
                            onChange={(e) => {
                                setMail({ ...mail, subject: e.target.value });
                            }}
                        />
                        <div>
                            <div
                                className="App"
                                style={{ marginBottom: "20px" }}
                            >
                                <CKEditor
                                    editor={ClassicEditor}
                                    data=""
                                    config={
                                        {
                                            // image: {
                                            //     toolbar: ["imageTextAlternative"],
                                            //     styles: {},
                                            // },
                                            // removePlugins: ["ImageResize"],
                                        }
                                    }
                                    onReady={(editor: any) => {
                                        editor.plugins.get(
                                            "FileRepository"
                                        ).createUploadAdapter = (
                                            loader: any
                                        ) => {
                                            return new CloudinaryUploadAdapter(
                                                loader
                                            );
                                        };
                                    }}
                                    onChange={(event, batchInfo) => {
                                        const updatedData =
                                            enforceResponsiveImages(
                                                batchInfo.getData()
                                            );
                                        setMail({
                                            ...mail,
                                            mail_body: updatedData,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                marginTop: 10,
                                marginBottom: 10,
                            }}
                        >
                            {attachments.length > 0 &&
                                attachments.map((file, index) => {
                                    return (
                                        <div
                                            key={index}
                                            style={{ marginRight: 5 }}
                                        >
                                            <Card sx={{ padding: 1 }}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginTop: 10,
                                                    }}
                                                >
                                                    <Avatar>
                                                        {file.type.includes(
                                                            "image"
                                                        ) ? (
                                                            <Image />
                                                        ) : (
                                                            <PictureAsPdf />
                                                        )}
                                                    </Avatar>
                                                    <div style={{ padding: 5 }}>
                                                        <div>
                                                            {file.name.length >
                                                            10
                                                                ? `${file.name.slice(
                                                                      0,
                                                                      10
                                                                  )}...`
                                                                : file.name}
                                                        </div>
                                                        <div>
                                                            {(
                                                                file.size / 1024
                                                            ).toFixed(2)}{" "}
                                                            KB
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginTop: 10,
                                                    }}
                                                >
                                                    <button
                                                        onClick={() => {
                                                            removeFile(file);
                                                        }}
                                                    >
                                                        <Delete />
                                                    </button>
                                                </div>
                                            </Card>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    <Stack spacing={10} direction="row">
                        {/* <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
            Upload Attachments
            <VisuallyHiddenInput onChange={(event: ChangeEvent<HTMLInputElement>) => { handleAttachments(event) }} multiple type="file" />
          </Button> */}
                        <Button
                            sx={{
                                fontSize: "20px",
                                padding: "10px 40px",
                                marginBottom: "1500px",
                            }}
                            onClick={sendMail}
                            variant="contained"
                            endIcon={<Send />}
                        >
                            Send
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Wrapper>
    );
};
class CloudinaryUploadAdapter {
    private loader: any;
    private url: string;

    constructor(loader: any) {
        this.loader = loader;
        this.url = `https://api.cloudinary.com/v1_1/dyrqnlsfx/image/upload`; // Replace <cloud_name> with your Cloudinary cloud name
    }

    upload(): Promise<{
        default: string;
    }> {
        return this.loader.file
            .then((imageFile: File) => {
                if (!imageFile.type.startsWith("image/")) {
                    return Promise.reject("The file is not an image.");
                }

                return new Promise((resolve, reject) => {
                    const formData = new FormData();
                    formData.append("upload_preset", "Email Test"); // Replace <upload_preset> with your unsigned preset
                    formData.append("file", imageFile);

                    fetch(this.url, {
                        method: "POST",
                        body: formData,
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.secure_url) {
                                resolve({
                                    default: data.secure_url, // The URL to display the image in the editor
                                });
                            } else {
                                reject(
                                    data.error?.message ||
                                        "Image upload failed."
                                );
                            }
                        })
                        .catch((error) => {
                            reject(
                                error.message ||
                                    "Network error occurred during upload."
                            );
                        });
                });
            })
            .catch(() => Promise.reject("Failed to load image file."));
    }

    abort(): void {
        // Handle upload cancellation if required
    }
}
export default EmailPage;
