import AdminPanelSettingsOutlined from "@mui/icons-material/AdminPanelSettingsOutlined";
import WorkOutlineOutlined from "@mui/icons-material/WorkOutlineOutlined";
import Groups2Outlined from "@mui/icons-material/Groups2Outlined";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import WalletOutlined from "@mui/icons-material/WalletOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import PaidIcon from "@mui/icons-material/Paid";
import ListItemButton from "@mui/material/ListItemButton";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import InventoryIcon from "@mui/icons-material/Inventory";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import PaymentIcon from "@mui/icons-material/Payment";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { NavLink } from "react-router-dom";
import Image from "Components/Shared/image";
import { clientLogout } from "Utils";
import { LinksFunc } from "./type";
import { Balance, Mail, PendingOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { UsersDetails } from "Utils/interface";

const Links: LinksFunc = () => {
  const iconSx = { mr: 1.5, fontSize: 20 };
  const [userDetails, setUserDetails] = useState<UsersDetails | null>(null);
  useEffect(() => {
    const userDetailsString = localStorage.getItem("user");
    if (userDetailsString) {
      const userDetailsObject = JSON.parse(userDetailsString);
      setUserDetails(userDetailsObject);
    }
  }, []);

  const links = [
    {
      link: "Dashboard",
      to: "/",
      icon: <HomeOutlinedIcon htmlColor="#737272" sx={iconSx} />,
    },
    {
      link: "Admin",
      to: "/admin",
      icon: <AdminPanelSettingsOutlined htmlColor="#737272" sx={iconSx} />,
    },
    {
      link: "KYC",
      to: "/kyc-types",
      icon: <WalletOutlined htmlColor="#737272" sx={iconSx} />,
    },
    {
      link: "Roles",
      to: "/roles",
      icon: <WorkOutlineOutlined htmlColor="#737272" sx={iconSx} />,
    },
    {
      link: "Subscribers",
      to: "/subscribers",
      icon: <Groups2Outlined htmlColor="#737272" sx={iconSx} />,
    },
    {
      link: "Users",
      to: "/users",
      icon: <Groups2Outlined htmlColor="#737272" sx={iconSx} />,
    },
    {
      link: "Config type",
      to: "/config-type",
      icon: <SettingsSuggestIcon htmlColor="#737272" sx={iconSx} />,
    },
    {
      link: "Configuration",
      to: "/configurations",
      icon: <SettingsOutlined htmlColor="#737272" sx={iconSx} />,
    },
    {
      link: "Offers",
      to: "/offers",
      icon: <LocalOfferIcon htmlColor="#737272" sx={iconSx} />,
    },
    {
      link: "Orders",
      to: "/orders",
      icon: <InventoryIcon htmlColor="#737272" sx={iconSx} />,
    },
    {
      link: "Transactions",
      to: "/transactions",
      icon: <PaidIcon htmlColor="#737272" sx={iconSx} />,
    },
    {
      link: "Balances",
      to: "/balance-summary",
      icon: <Balance htmlColor="#737272" sx={iconSx} />,
    },
    {
      link: "Kyc Request",
      to: "/kyc-requests",
      icon: <PendingOutlined htmlColor="#737272" sx={iconSx} />,
    },
    {
      link: "Bulk Mail",
      to: "/send-mail",
      icon: <Mail htmlColor="#737272" sx={iconSx} />,
    },
    {
      link: "Tuition Config",
      to: "/pay-tuition",
      icon: <ReceiptIcon htmlColor="#737272" sx={iconSx} />,
    },
    {
      link: "Paid Tuition",
      to: "/school-fee-payment",
      icon: <TaskAltIcon htmlColor="#737272" sx={iconSx} />,
    },
    {
      link: "BDC List",
      to: "/bdc-list",
      icon: <CurrencyExchangeIcon htmlColor="#737272" sx={iconSx} />,
    },
    {
      link: "BDC Fee",
      to: "/bdc-fee-history",
      icon: <CurrencyExchangeIcon htmlColor="#737272" sx={iconSx} />,
    },
    {
      link: "Nsano",
      to: "/nsano",
      icon: <PaymentIcon htmlColor="#737272" sx={iconSx} />,
    },
    {
      link: "Currency-Pair Configuration",
      to: "/currency-pairs-config",
      icon: <CurrencyExchangeIcon htmlColor="#737272" sx={iconSx} />,
    },
  ];

  let filteredLinks;
  if (userDetails?.role === "MARKETING") {
    // If user has specific routes defined, filter the links
    filteredLinks = [
      {
        link: "Dashboard",
        to: "/",
        icon: <HomeOutlinedIcon htmlColor="#737272" sx={iconSx} />,
      },

      {
        link: "Subscribers",
        to: "/subscribers",
        icon: <Groups2Outlined htmlColor="#737272" sx={iconSx} />,
      },
      {
        link: "Users",
        to: "/users",
        icon: <Groups2Outlined htmlColor="#737272" sx={iconSx} />,
      },
    ];
  } else if (userDetails?.role === "COMPLIANCE") {
    filteredLinks = [
      {
        link: "Dashboard",
        to: "/",
        icon: <HomeOutlinedIcon htmlColor="#737272" sx={iconSx} />,
      },
      {
        link: "Users",
        to: "/users",
        icon: <Groups2Outlined htmlColor="#737272" sx={iconSx} />,
      },
      {
        link: "Kyc Request",
        to: "/kyc-requests",
        icon: <PendingOutlined htmlColor="#737272" sx={iconSx} />,
      },
      {
        link: "KYC",
        to: "/kyc-types",
        icon: <WalletOutlined htmlColor="#737272" sx={iconSx} />,
      },
      {
        link: "Offers",
        to: "/offers",
        icon: <LocalOfferIcon htmlColor="#737272" sx={iconSx} />,
      },
      {
        link: "Orders",
        to: "/orders",
        icon: <InventoryIcon htmlColor="#737272" sx={iconSx} />,
      },
      {
        link: "Transactions",
        to: "/transactions",
        icon: <PaidIcon htmlColor="#737272" sx={iconSx} />,
      },
    ];
  } else {
    // Display all links for other roles
    filteredLinks = links;
  }

  return (
    <div style={{ overflowY: "hidden" }}>
      <Box textAlign="center" py={2}>
        <Image
          src="/images/logo.svg"
          alt="monirates logo"
          height="100%"
          width={150}
        />
      </Box>
      <Stack spacing={0.9}>
        {filteredLinks.map(({ icon, link, to }, index) => (
          <NavLink
            key={index}
            to={to}
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <ListItemButton>
              {icon}
              <Typography
                variant="body1"
                style={{ fontSize: 15 }}
                fontWeight={400}
              >
                {link}
              </Typography>
            </ListItemButton>
          </NavLink>
        ))}
      </Stack>
      <ListItemButton sx={{ mt: 2 }} onClick={() => clientLogout()}>
        <LogoutOutlined sx={{ color: "red", mr: 1.5 }} />
        <Typography
          variant="body1"
          style={{ fontSize: 15, color: "red" }}
          fontWeight={400}
        >
          Logout
        </Typography>
      </ListItemButton>
    </div>
  );
};
export default Links;
