import React, { useState } from "react";
import Menus from "Components/Shared/menu";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import MoreVert from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import { useDailog, useMenu } from "Utils/hooks";
import { OrderMenuFunc } from "./type";
import OrderDetails from "./orderDetails";
import ConfirmCancel from "./confirm";
import { request } from "Utils";

const OrderMenu: OrderMenuFunc = (props) => {
    const { anchorEl, menuIsOpen, closeMenu, openMenu } = useMenu();
    const { isOpen, openDialog, closeDialog } = useDailog();
    const cancelOrder = useDailog();

    const [paymentProofDialogOpen, setPaymentProofDialogOpen] = useState(false);
    const [paymentProofUrl, setPaymentProofUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const handleViewPaymentProof = async () => {
        setLoading(true);
        setPaymentProofDialogOpen(true);
        try {
            const { data } = await request.get({
                url: `/order/${props.order._id}/buyer-payment-proof-url`,
            });
            setPaymentProofUrl(data?.buyerPaymentProofUrl || null);
        } catch (error) {
            console.error("Failed to fetch payment proof:", error);
            setPaymentProofUrl(null);
        } finally {
            setLoading(false);
        }
    };

    const closePaymentProofDialog = () => {
        setPaymentProofDialogOpen(false);
        setPaymentProofUrl(null);
    };

    return (
        <>
            <IconButton onClick={openMenu}>
                <MoreVert />
            </IconButton>
            <Menus anchorEl={anchorEl} open={menuIsOpen} closeMenu={closeMenu}>
                <div>
                    <MenuItem onClick={openDialog}>View Bank Details</MenuItem>
                    {props.order.buyerPaymentProofUrl && (
                        <MenuItem onClick={handleViewPaymentProof}>
                            View Payment Proof
                        </MenuItem>
                    )}
                    {!props.order.sellerReceivedPayment &&
                        props.order.status !== "CANCELLED" && (
                            <MenuItem
                                onClick={cancelOrder.openDialog}
                                sx={{ color: "red" }}
                            >
                                Cancel Order
                            </MenuItem>
                        )}
                </div>
            </Menus>
            <OrderDetails
                isOpen={isOpen}
                closeDialog={closeDialog}
                order={props.order}
            />
            <ConfirmCancel
                isOpen={cancelOrder.isOpen}
                closeDialog={cancelOrder.closeDialog}
                toggleToast={props.toggleToast}
                url={`/order/${props.order._id}/cancel`}
                refetch={props.refetch}
            />
            <Dialog
                open={paymentProofDialogOpen}
                onClose={closePaymentProofDialog}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Payment Proof</DialogTitle>
                <DialogContent>
                    {loading ? (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="200px"
                        >
                            <CircularProgress />
                        </Box>
                    ) : paymentProofUrl && paymentProofUrl !== "none" ? (
                        <img
                            src={paymentProofUrl}
                            alt="Payment Proof"
                            style={{
                                width: "100%",
                                height: "auto",
                                borderRadius: "8px",
                            }}
                        />
                    ) : (
                        <Box textAlign="center">
                            <p>No payment proof available.</p>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default OrderMenu;
