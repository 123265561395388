import Box from "@mui/material/Box";
import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useQuery } from "react-query";
import { getQueryString, request, toLocaleDate } from "Utils";
import { UserInt } from "Utils/interface";
import Usermenu from "./menu";
import NoContentFound from "Components/Shared/noContentFound";
import { useLocation, useNavigate } from "react-router-dom";
import { UserFunc } from "./type";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import UserFilter from "./filter";

const getUsers = async ({ queryKey }: any) => {
    const [, { pageId, limit, filter }] = queryKey;
    const query = { pageId, limit };
    const queryParams = filter
        ? `${filter}&pageId=${pageId}&limit=${limit || 10}`
        : getQueryString(query);
    const { data } = await request.get({
        url: `/users${queryParams}`,
    });
    return data;
};

const UsersPage: UserFunc = () => {
    const { toggleToast, toastMessage } = useToast();
    const [pageId, setPageId] = useState(1);
    const [limit, setLimit] = useState(10);

    const navigate = useNavigate();
    const query = useLocation();

    const { data, error, isLoading, refetch } = useQuery({
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        queryKey: ["Users", { pageId, limit, filter: query.search }],
        queryFn: getUsers,
        retry: 2,
        // staleTime: 86400000,
    });

    const sendBulkMail = () => {
        const filterByUnsubscribe = data?.users?.filter(
            (user: { unsubscribe: boolean }) => user.unsubscribe !== true
        );

        if (filterByUnsubscribe.length != data?.users?.length) {
            alert(
                "The email list has been modified to exclude unsubscribed users."
            );
        }
        navigate(`/send-mail`, {
            state: { data: JSON.stringify(filterByUnsubscribe) },
        });
    };

    const sendPushNotification = () => {
        navigate(`/push-notification`, {
            state: { data: JSON.stringify(data?.users) },
        });
    };

    const columns = [
        { minWidth: 20, name: "No", key: "index" },
        { minWidth: 100, name: "Last name", key: "lastname" },
        { minWidth: 100, name: "First name", key: "firstname" },
        { minWidth: 50, name: "Username", key: "username" },
        { minWidth: 50, name: "Email", key: "email" },
        { minWidth: 50, name: "phoneNumber", key: "phoneNumber" },
        { minWidth: 50, name: "Nationality", key: "nationality" },
        { minWidth: 50, name: "Gender", key: "gender" },
        { minWidth: 50, name: "Status", key: "status" },
        { minWidth: 50, name: "KYC", key: "hasDoneGeneralKyc" },
        { minWidth: 150, name: "Created At", key: "createdAt" },
        { minWidth: 100, name: "Referral", key: "referralUsername" },
        { minWidth: 50, name: "Action", key: "action" },
    ];

    const users = data?.users?.map((user: UserInt, index: number) => ({
        index: ++index + limit * (pageId - 1),
        ...user,
        hasDoneGeneralKyc: user.hasDoneGeneralKyc ? "Yes" : "NO",
        createdAt: toLocaleDate(user?.createdAt),
        action: (
            <Usermenu
                firstname={user.firstname}
                lastname={user.lastname}
                userEmail={user.email}
                userId={user.id}
                walletId={user.walletId}
                globalId={user.globalId}
                defaultStatus={user.status}
                userType={user.userType}
                toggleToast={toggleToast}
                refetch={refetch}
            />
        ),
    }));

    if (isLoading) return <Loading />;
    if (error) return <ErrorPage error={error} />;

    return (
        <Wrapper>
            <div>
                {data?.users?.length ? (
                    <Box pb={5}>
                        <Box textAlign="right">
                            <UserFilter
                                sendBulkMail={sendBulkMail}
                                sendPushNotification={sendPushNotification}
                            />
                        </Box>
                        <StickyHeadTable
                            refetch={refetch}
                            data={users}
                            dataLength={data?.totalCount}
                            columns={columns}
                            setLimit={setLimit}
                            limit={limit}
                            setPageId={setPageId}
                            pageId={pageId}
                            pageCount={data.pageCount}
                        />
                    </Box>
                ) : (
                    <NoContentFound />
                )}
                {Boolean(toastMessage) && (
                    <Toast
                        open={Boolean(toastMessage)}
                        message={toastMessage}
                        onClose={() => toggleToast("")}
                    />
                )}
            </div>
        </Wrapper>
    );
};

export default UsersPage;
