import Box from "@mui/material/Box";
import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useQuery } from "react-query";
import { getQueryString, request, toLocaleDate } from "Utils";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import NoContentFound from "Components/Shared/noContentFound";
import { useLocation } from "react-router-dom";
import StickyHeadTable from "Components/Shared/table";
import OfferMenu from "./menu";
import { OfferInt, OffersPageFunc } from "./type";
import OfferFilterAlign from "./filter_align";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const getOffers = async ({ queryKey }: any) => {
  const [, { pageId, limit, filter }] = queryKey;
  const query = {
    pageId,
    limit,
  };
  const queryParams = filter
    ? `${filter}&pageId=${pageId}&limit=${limit || 10}`
    : getQueryString(query);
  const { data } = await request.get({ url: `/offers${queryParams}` });
  return data;
};

const OffersPage: OffersPageFunc = () => {
  const { toggleToast, toastMessage } = useToast();
  const [filter, setFilter] = useState("");
  const [pageId, setPageId] = useState(1);
  const [limit, setLimit] = useState(10);
  const query = useLocation();
  // const userId = localStorage.getItem("userId") ?? "";
  // const [userData, setUserData] = useState({ firstname: "", lastname: "" });

  // const status = getQuery.get("status");
  // const currency = getQuery.get("currency");
  // const globalId = getQuery.get("globalId");
  // const offerGlobalId = getQuery.get("offerGlobalId");
  // const fromCurrency = getQuery.get("fromCurrency");
  // const toCurrency = getQuery.get("toCurrency");

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: [
      "Users",
      {
        pageId,
        limit,
        filter: query.search,
      },
    ],
    queryFn: getOffers,
    retry: 2,
    // staleTime: 86400000,
  });
  // useEffect(() => {
  //   if (userId != "") {
  //     getUserData(userId).then((data) => {
  //       setUserData(data);
  //     });
  //   }
  // }, []);
  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Available", key: "available" },
    { minWidth: 50, name: "Initial Amount", key: "initialAmount" },
    { minWidth: 50, name: "Exchange Rate", key: "displayRate" },
    { minWidth: 50, name: "From Currency", key: "fromCurrency" },
    { minWidth: 50, name: "To Currency", key: "toCurrency" },
    { minWidth: 50, name: "Created By", key: "createdBy" },
    { minWidth: 50, name: "Created At", key: "createdAt" },
    { minWidth: 50, name: "Status", key: "status" },
    { minWidth: 50, name: "Min Amount", key: "minAmount" },
    { minWidth: 50, name: "Offer Reference", key: "globalId" },
    { minWidth: 50, name: "Action", key: "action" },
  ];
  const offers = data?.offers?.map((offer: OfferInt, index: number) => ({
    index: ++index + limit * (pageId - 1),
    ...offer,
    available: `${offer?.fromCurrency?.symbol}${offer.available.toLocaleString(
      "en"
    )}`,
    minAmount: `${offer?.fromCurrency?.symbol}${offer?.minimumAmountSaleable.toLocaleString("en")}`,

    initialAmount: `${offer?.fromCurrency
      ?.symbol}${offer.initialAmount.toLocaleString("en")}`,
    displayRate: (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src={offer?.fromCurrency?.flag}
          alt={offer.fromCurrency.symbol}
          variant="square"
          sx={{ width: 20, height: 15, mr: 0.5 }}
        />{" "}
        <Typography fontWeight={400}>
          {offer.fromCurrency.symbol}
          {offer?.fromCurrency?.displayRate}
        </Typography>
        <SyncAltIcon
          sx={{ fontSize: "15px", color: "gray", ml: 0.5, mr: 0.5 }}
        />
        <Typography fontWeight={400}>
          {offer.toCurrency.symbol}
          {offer?.toCurrency?.displayRate}
        </Typography>
        <Avatar
          src={offer?.toCurrency?.flag}
          alt={offer.toCurrency.symbol}
          variant="square"
          sx={{ width: 20, height: 15, ml: 0.5 }}
        />
      </Box>
    ),
    fromCurrency: offer?.fromCurrency?.name,
    toCurrency: offer?.toCurrency?.name,
    createdBy: `${offer?.userGlobalId?.firstname}  ${offer?.userGlobalId?.lastname}`,
    createdAt: toLocaleDate(offer?.createdAt),
    globalId: (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={offer.globalId}>
          <span>{`${offer.globalId.substring(0, 4)}...`}</span>
        </Tooltip>
        <IconButton
          size="small"
          onClick={() => navigator.clipboard.writeText(offer.globalId)}
        >
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Box>
    ),
    action: <OfferMenu offerGlobalId={offer._id} globalId={offer.globalId} />,
    // _id: (
    //   <Box sx={{ display: "flex", alignItems: "center" }}>
    //     <Tooltip title={offer._id}>
    //       <span>{`${offer._id.substring(0, 4)}..`}</span>
    //     </Tooltip>
    //     <IconButton
    //       size="small"
    //       onClick={() => navigator.clipboard.writeText(offer._id)}
    //     >
    //       <ContentCopyIcon fontSize="small" />
    //     </IconButton>
    //   </Box>
    // ),
    // globalId: (
    //   <Box sx={{ display: "flex", alignItems: "center" }}>
    //     <Tooltip title={offer.globalId}>
    //       <span>{`${offer.globalId.substring(0, 4)}..`}</span>
    //     </Tooltip>
    //     <IconButton
    //       size="small"
    //       onClick={() => navigator.clipboard.writeText(offer.globalId)}
    //     >
    //       <ContentCopyIcon fontSize="small" />
    //     </IconButton>
    //   </Box>
    // ),
  }));

  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      {/* <p>{userData?.firstname} {userData?.lastname}</p> */}

      <Box sx={{ height: "85.5vh", position: "relative" }}>
        {data?.offers?.length ? (
          <Box pb={5}>
            {filter ? (
              <Box
                onClick={() => window.location.reload()}
                marginTop="-40px"
                display="flex"
                alignItems="center"
              >
                <ArrowBackIcon
                  style={{ fontSize: 40 }}
                  sx={{ "&:hover": { cursor: "pointer" } }}
                />
              </Box>
            ) : (
              ""
            )}
            <OfferFilterAlign setFilter={setFilter} />
            {/* <Box textAlign="right">
              <OfferFilter setFilter={setFilter}/>
            </Box> */}
            <StickyHeadTable
              refetch={refetch}
              data={offers}
              dataLength={data?.totalCount}
              columns={columns}
              setLimit={setLimit}
              limit={limit}
              setPageId={setPageId}
              pageId={pageId}
              pageCount={data.pageCount}
            />
          </Box>
        ) : (
          <NoContentFound />
        )}

        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
      </Box>
    </Wrapper>
  );
};
export default OffersPage;
