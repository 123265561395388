import { ChangeEvent, useState } from "react";
import { UseFormFunc } from "./interface";
export const useForm: UseFormFunc = (submitAction = null, initialValues = {}) => {
  const [values, setValues] = useState<any>(initialValues);
  console.log("initialValues ", initialValues);
  const getData = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked, id } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setValues({
      ...values,
      [name || id]: newValue,
    });
  };

  const submit = (e: any) => {
    e.preventDefault();
    if (submitAction) submitAction();
  };

  const reset = () => {
    setValues(initialValues);
  };
  return {
    values,
    getData,
    submit,
    reset,
  };
};

export const useToast = () => {
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastOptions, setToastOptions] = useState<{
    variant?: string;
    onClose?: ((confirmed: boolean) => void) | undefined;
    actionButtons?: { label: string; onClick: () => void }[];
  }>({});

  const toggleToast = (message: string, options?: typeof toastOptions) => {
    setToastMessage(message);
    setToastOptions(options || {});
  };

  const closeToast = () => {
    setToastMessage(null);
    if (toastOptions.onClose) {
      toastOptions.onClose(false);
    }
  };

  return { toastMessage, toggleToast, closeToast, toastOptions };
};

export const useDailog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);
  return { isOpen, closeDialog, openDialog };
}
export const useMenu: any = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuIsOpen = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  return {
    anchorEl,
    openMenu,
    menuIsOpen,
    closeMenu,
  };
};
