import Box from "@mui/material/Box";
import Wrapper from "Components/Wrapper";
import KycCard from "./card";
import { Grid } from "@mui/material";
import { KycRequestPageFunc } from "./type";

const KycRequestPage: KycRequestPageFunc = () => {
  const status = [
    { title: "Approved KYC", status: "approved" },
    { title: "Pending KYC", status: "pending" },
    { title: "Rejected KYC", status: "rejected" },
  ];
  return (
    <Wrapper>
      <Box pb={4} component="section">
        <Grid container spacing={2}>
          {Array.isArray(status) &&
            status?.map((status: any, index: number) => (
              <Grid key={index} item xs={12} sm={4} md={3}>
                <KycCard status={status} />
              </Grid>
            ))}
        </Grid>
      </Box>
    </Wrapper>
  );
};
export default KycRequestPage;
