import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import ActionButton from "Components/Shared/button";
import InputField from "Components/Shared/textField";
import MenuItem from '@mui/material/MenuItem';
import DialogComponent from "Components/Shared/dailog";
import Progress from "Components/Shared/circleLoading";
import Box from "@mui/material/Box";
import { request } from "Utils";
import { useForm } from "Utils/hooks";
import { useMutation } from "react-query";




const CurrencyPairDialog = ({ isOpen, closeDialog, toggleToast = null, refetch = null, apiEndpoint, currencyPairData, countryList, title, isUpdate }: any) => {
    const { values, getData, reset } = useForm(null, {
        fromCurrencyIso2: currencyPairData?.fromCurrencyIso2,
        toCurrencyIso2: currencyPairData?.toCurrencyIso2,
        value: currencyPairData?.config.value,
        type: currencyPairData?.config.type
    });
    const [isLoading, setIsLoading] = useState(false);
    const sx = {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        alignItems: "center",
        width: "100%"
    };

    const mutation = useMutation(
        async () => {
            const reqAction = isUpdate ? "patch" : "post";
            const reqUrl = isUpdate ? `${apiEndpoint}/${currencyPairData?._id}` : apiEndpoint;
            return await request[reqAction]({
                url: reqUrl, // Use the passed `apiEndpoint`
                data: {
                    fromCurrencyIso2: values.fromCurrencyIso2,
                    toCurrencyIso2: values.toCurrencyIso2,
                    config: {
                        value: Number(values.value),
                        type: values.type
                    }
                }
            });
        },
        {
            onSuccess: ({ message }) => {
                reset();
                toggleToast(message);
                refetch();
                //setIsloading(false);
                closeDialog();
            },
            onError: ({ message }) => {
                toggleToast(message);
                //setIsloading(false);
            },
        },
    );

    useEffect(() => {
        if (currencyPairData) {
            reset({
                fromCurrencyIso2: currencyPairData.fromCurrencyIso2,
                toCurrencyIso2: currencyPairData.toCurrencyIso2,
                value: currencyPairData.config.value,
                type: currencyPairData.config.type,
            });
        }
    }, [currencyPairData]);

    const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        mutation.mutate();
    }

    return (
        <DialogComponent
            open={isOpen}
            onClose={closeDialog}
            title={title}
            maxWidth="sm"
        >
            <Stack spacing={3} py={2}>
                <form onSubmit={submitForm} >
                    <Stack spacing={3} py={3}>
                        <Box sx={sx}>
                            <InputField

                                label="From-Currency Iso2"
                                name="fromCurrencyIso2"
                                select
                                onChange={getData}
                                sx={{ width: { xs: "100%", md: "48%" }, marginBottom: { md: "10px", sm: "10px" } }}
                                required
                                defaultValue={currencyPairData?.fromCurrencyIso2 || ""}
                            >
                                {countryList.map((option: any) => (
                                    <MenuItem key={`fromCurrencyIso2${option.name}`} value={option.iso2}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </InputField>
                            <InputField
                                label="To-Currency Iso2"
                                name="toCurrencyIso2"
                                select
                                onChange={getData}
                                sx={{ width: { xs: "100%", md: "48%" } }}
                                required
                                defaultValue={currencyPairData?.toCurrencyIso2 || ""}
                            >
                                {countryList.map((option: any) => (
                                    <MenuItem key={`toCurrencyIso2${option.name}`} value={option.iso2}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </InputField>
                        </Box>

                        <Box sx={sx}>

                            <InputField
                                type="number"
                                label="Fee"
                                name="value"
                                onChange={getData}
                                sx={{ width: { xs: "100%", md: "48%" }, marginBottom: { md: "10px", sm: "10px" } }}
                                required
                                defaultValue={currencyPairData?.config?.value || ""}
                                inputProps={{
                                    step: "0.0001" // Allows up to two decimal places
                                }}
                            />
                            <InputField
                                label="Type"
                                name="type"
                                select
                                onChange={getData}
                                sx={{ width: { xs: "100%", md: "48%" } }}
                                required
                                defaultValue={currencyPairData?.config?.type || ""}
                            >
                                {["FIXED", "PERCENTAGE"].map((option: any) => (
                                    <MenuItem key={`toCurrencyIso2${option}`} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </InputField>
                        </Box>


                        <ActionButton type="submit" variant="contained">
                            Submit
                            {isLoading && (
                                <Progress
                                    sx={{
                                        width: { xs: "100%", md: "40%" },
                                        mb: 2,
                                    }}
                                    size={18}
                                />
                            )}
                        </ActionButton>
                    </Stack>
                </form>
            </Stack>
        </DialogComponent>
    )
}
export default CurrencyPairDialog;