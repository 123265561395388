import { useState, useMemo } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import Wrapper from "Components/Wrapper";
import { useQuery } from "react-query";
import { request, toLocaleDate, toLocaleDateOnly } from "Utils";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import NoContentFound from "Components/Shared/noContentFound";
import StickyHeadTable from "Components/Shared/table";
import { KycRequestInt, KycRequestPageFunc } from "./type";
import KycMenu from "./menu";

type PurposeFilter = "ALL" | "GENERAL_KYC";
type DateFilter =
  | "ALL"
  | "LAST_7_DAYS"
  | "LAST_30_DAYS"
  | "LAST_90_DAYS"
  | "CUSTOM";

const getKycRequests = async ({ queryKey }: any) => {
  const { data } = await request.get({ url: `/kyc-requests?status=REJECTED` });
  return data;
};

const KycRequestPagePending: KycRequestPageFunc = () => {
  const { toggleToast, toastMessage } = useToast();
  const [pageId, setPageId] = useState(1);
  const [limit, setLimit] = useState(10);
  const [purposeFilter, setPurposeFilter] = useState<PurposeFilter>("ALL");
  const [dateFilter, setDateFilter] = useState<DateFilter>("ALL");
  const [customStartDate, setCustomStartDate] = useState<string>("");
  const [customEndDate, setCustomEndDate] = useState<string>("");

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: [],
    queryFn: getKycRequests,
    retry: 2,
    // staleTime: 86400000,
  });

  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Id", key: "_id" },
    { minWidth: 50, name: "First Name", key: "first_name" },
    { minWidth: 50, name: "Last Name", key: "last_name" },
    { minWidth: 50, name: "Purpose", key: "purpose" },
    { minWidth: 50, name: "Created At", key: "createdAt" },
    { minWidth: 50, name: "Date of Birth", key: "dob" },
    { minWidth: 50, name: "Nationality", key: "nationality" },
    { minWidth: 50, name: "Action", key: "action" },
  ];

  const filterByPurpose = (kycRequests: KycRequestInt[]) => {
    if (purposeFilter === "ALL") return kycRequests;
    return kycRequests.filter((request) => request.purpose === purposeFilter);
  };

  const filterByDate = (kycRequests: KycRequestInt[]) => {
    const now = new Date();
    let startDate = new Date(0); // January 1, 1970

    switch (dateFilter) {
      case "LAST_7_DAYS":
        startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        break;
      case "LAST_30_DAYS":
        startDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
        break;
      case "LAST_90_DAYS":
        startDate = new Date(now.getTime() - 90 * 24 * 60 * 60 * 1000);
        break;
      case "CUSTOM":
        startDate = new Date(customStartDate);
        now.setTime(new Date(customEndDate).getTime() + 24 * 60 * 60 * 1000);
        break;
      default:
        return kycRequests;
    }

    return kycRequests.filter(
      (request) =>
        new Date(request.createdAt) >= startDate &&
        new Date(request.createdAt) <= now
    );
  };

  const filteredKycRequests = useMemo(() => {
    if (!data) return [];
    return filterByDate(filterByPurpose(data));
  }, [data, purposeFilter, dateFilter, customStartDate, customEndDate]);

  const kycRequests = filteredKycRequests.map(
    (kycRequest: KycRequestInt, index: number) => {
      const dob = kycRequest?.userId?.dob;
      const validDob =
        dob && !isNaN(new Date(dob).getTime())
          ? toLocaleDateOnly(dob)
          : "Invalid Date";
      return {
        index: ++index + limit * (pageId - 1),
        ...kycRequest,
        first_name: kycRequest?.userId?.firstname,
        last_name: kycRequest?.userId?.lastname,
        createdAt: toLocaleDate(kycRequest?.createdAt),
        dob: validDob,
        nationality: kycRequest?.userId?.nationality,
        action: (
          <KycMenu
            userId={kycRequest?.userId?._id}
            kycRequest={kycRequest}
            toggleToast={toggleToast}
            refetch={refetch}
          />
        ),
      };
    }
  );

  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;

  const handlePurposeChange = (event: SelectChangeEvent<PurposeFilter>) => {
    setPurposeFilter(event.target.value as PurposeFilter);
  };

  const handleDateChange = (event: SelectChangeEvent<DateFilter>) => {
    setDateFilter(event.target.value as DateFilter);
  };

  return (
    <Wrapper>
      <Box sx={{ height: "85.5vh", position: "relative" }}>
        <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="purpose-filter-label">Purpose</InputLabel>
            <Select<PurposeFilter>
              labelId="purpose-filter-label"
              value={purposeFilter}
              onChange={handlePurposeChange}
              label="Purpose"
            >
              <MenuItem value="ALL">All</MenuItem>
              <MenuItem value="GENERAL_KYC">GENERAL_KYC</MenuItem>
              <MenuItem value="OTHER">Other</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="date-filter-label">Date Range</InputLabel>
            <Select<DateFilter>
              labelId="date-filter-label"
              value={dateFilter}
              onChange={handleDateChange}
              label="Date Range"
            >
              <MenuItem value="ALL">All Time</MenuItem>
              <MenuItem value="LAST_7_DAYS">Last 7 Days</MenuItem>
              <MenuItem value="LAST_30_DAYS">Last 30 Days</MenuItem>
              <MenuItem value="LAST_90_DAYS">Last 90 Days</MenuItem>
              <MenuItem value="CUSTOM">Custom Range</MenuItem>
            </Select>
          </FormControl>
          {dateFilter === "CUSTOM" && (
            <>
              <TextField
                label="Start Date"
                type="date"
                value={customStartDate}
                onChange={(e) => setCustomStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="End Date"
                type="date"
                value={customEndDate}
                onChange={(e) => setCustomEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </>
          )}
        </Box>
        {kycRequests?.length ? (
          <Box pb={5}>
            <StickyHeadTable
              refetch={refetch}
              data={kycRequests}
              dataLength={data?.totalCount}
              columns={columns}
              setLimit={setLimit}
              limit={limit}
              setPageId={setPageId}
              pageId={pageId}
              pageCount={data.pageCount}
            />
          </Box>
        ) : (
          <NoContentFound />
        )}
        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
      </Box>
    </Wrapper>
  );
};
export default KycRequestPagePending;
